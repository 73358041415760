import React, { useRef } from "react";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";

import {
  EMessageActionType,
  ICarousel,
  ICarouselList,
  IMessageAction,
} from "@types";
import { Img } from "react-image";
import { ImageLoader } from "./ImageLoader";
import { createDataAttr } from "@utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";

export type ICarouselMessageProps = {
  message: ICarouselList;
  onSendAction: (action: IMessageAction) => void;
};

export const CarouselMessage = ({
  message,
  onSendAction,
}: ICarouselMessageProps) => {
  const carouselItemsRef = useRef<HTMLDivElement[]>([]);

  const _renderButton = (button: IMessageAction) => {
    if (button.type === EMessageActionType.DATE_TIME_PICKER) {
      <button
        className="max-w-[230px] cursor-pointer overflow-hidden text-ellipsis py-4 text-blue-700"
        {...createDataAttr("carousel-column-button")}
      >
        {button.label}
      </button>;
    }
    // link action
    if (button.type === EMessageActionType.URI_ACTION) {
      return (
        <a
          className="max-w-[230px] cursor-pointer overflow-hidden text-ellipsis text-blue-700"
          href={button.uri}
          target="_blank"
          {...createDataAttr("carousel-column-button")}
        >
          {button.label}
        </a>
      );
    }
    // call action
    if (button.type === EMessageActionType.CALL_ACTION) {
      return (
        <a
          className="max-w-[230px] cursor-pointer overflow-hidden text-ellipsis text-blue-700"
          href={`tel:${button.phoneNo}`}
          {...createDataAttr("carousel-column-button")}
        >
          {button.label}
        </a>
      );
    }

    return (
      <button
        className="max-w-[230px] cursor-pointer overflow-hidden text-ellipsis text-blue-700"
        onClick={(e) => {
          onSendAction(button);
        }}
        {...createDataAttr("carousel-column-button")}
      >
        {button.label}
      </button>
    );
  };

  const _renderImage = (element: ICarousel) => {
    // is square image
    if (message.imageAspectRatio === "square") {
      return (
        <Img
          className="max-h-[250px] min-h-[250px] w-full rounded-tl-[8px] rounded-tr-[8px] object-contain"
          src={element.thumbnailImageUrl}
          loading="lazy"
          loader={<ImageLoader width={250} height={250} />}
          {...createDataAttr("carousel-column-image")}
        />
      );
    }
    return (
      <Img
        className="max-h-[133px] min-h-[133px] w-full rounded-tl-[8px] rounded-tr-[8px] object-cover"
        src={element.thumbnailImageUrl}
        loading="lazy"
        loader={<ImageLoader width={250} height={250} />}
        {...createDataAttr("carousel-column-image")}
      />
    );
  };

  return (
    <div className="relative w-fit">
      <Splide
        hasTrack={false}
        options={{
          arrows: true,
          gap: "10px",
          autoWidth: true,
          drag: "free",
          pagination: false,
          snap: false,
          omitEnd: true,
          focus: 0,
          breakpoints: {
            200: {
              width: "250px",
            },
            380: {
              width: "300px",
            },
            460: {
              width: "400px",
            },
            640: {
              width: "580px",
            },
            840: {
              width: "771px",
            },
          },
          mediaQuery: "min",
        }}
        {...createDataAttr("carousel-message")}
      >
        <SplideTrack>
          {message?.columns?.map((element: ICarousel, index: number) => {
            return (
              <SplideSlide
                key={element.id}
                className="flex w-[250px] flex-col drop-shadow-md"
                {...createDataAttr("carousel-column")}
              >
                {_renderImage(element)}
                <div
                  ref={(ref: HTMLDivElement) =>
                    (carouselItemsRef.current[index] = ref as HTMLDivElement)
                  }
                  className="flex flex-1 flex-col justify-between break-keep rounded-bl-[8px] rounded-br-[8px] bg-white"
                >
                  <div
                    className="p-[8px]"
                    {...createDataAttr("carousel-column-detail")}
                  >
                    <div
                      title={element.title}
                      className="text-abrown overflow-hidden text-ellipsis text-[14px] font-medium text-gray-800"
                      {...createDataAttr("carousel-column-detail-title")}
                    >
                      {element.title}
                    </div>
                    <div
                      title={element.text}
                      className="text-abrown overflow-hidden text-ellipsis text-[14px] text-gray-500"
                      {...createDataAttr("carousel-column-detail-description")}
                    >
                      {element.text}
                    </div>
                  </div>
                  <div className="flex flex-col items-center gap-3 p-[8px]">
                    {element.actions.map((button: IMessageAction) => {
                      return (
                        <React.Fragment key={button.id}>
                          {_renderButton(button)}
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </SplideSlide>
            );
          })}
        </SplideTrack>
        <div className="splide__arrows">
          <button className="splide__arrow splide__arrow--prev left-[-35px]">
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
          <button className="splide__arrow splide__arrow--next right-[-35px]">
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </Splide>
    </div>
  );
};
